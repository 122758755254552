import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { HeadProvider } from "react-head";
import App from "./App";
import Password from "./Password";

import "./css/index.css";
import "./css/print.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Index() {
  const [password, setPassword] = useState("");
  const unixtime = Math.round(new Date().getTime() / 1000);

  if (password !== "Egon" && unixtime < 1722463200) {
    return <Password setPassword={setPassword} />;
  }

  return <App />;
}

root.render(
  <HeadProvider>
    <Index />
  </HeadProvider>
);
